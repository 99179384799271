import { cloneDate } from '@progress/kendo-date-math';
const isObject = value => value && typeof value === "object" && !Array.isArray(value);
const isHtmlElement = element => element instanceof HTMLElement;
const dateSetter = method => (date, value) => {
  const clone = cloneDate(date);
  clone[method](value);
  return clone;
};
/**
 * @hidden
 */
export const isPresent = value => value !== undefined && value !== null;
/**
 * @hidden
 */
export const isDocumentAvailable = () => !!document;
/**
 * @hidden
 */
export const isNumber = value => isPresent(value) && typeof value === "number" && !Number.isNaN(value);
/**
 * @hidden
 */
export const parseToInt = value => parseInt(value, 10);
/**
 * @hidden
 */
export const isParseableToInt = value => isNumber(parseToInt(value)) && /^[0-9]+$/.test(value);
/**
 * @hidden
 */
export const clamp = (value, min, max) => Math.min(max, Math.max(min, value));
/**
 * @hidden
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const extend = (...args) => Object.assign(...args);
/**
 * @hidden
 */
export const deepExtend = (target, ...sources) => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (!Object.prototype.hasOwnProperty.call(source, key) || key === "__proto__" || key === "constructor" || key === "prototype") {
        continue;
      }
      if (isHtmlElement(source[key])) {
        target[key] = source[key];
      } else if (isObject(source[key]) && !(source[key] instanceof Date)) {
        if (!target[key] || !isObject(target[key])) {
          extend(target, {
            [key]: {}
          });
        }
        deepExtend(target[key], source[key]);
      } else {
        extend(target, {
          [key]: source[key]
        });
      }
    }
  }
  return deepExtend(target, ...sources);
};
/**
 * @hidden
 */
// eslint-disable-next-line
export const noop = () => {};
/**
 * @hidden
 */
export const isFunction = fn => typeof fn === "function";
/**
 * @hidden
 */
export const cropTwoDigitYear = date => {
  if (!isPresent(date) || isNaN(date.getTime())) {
    return 0;
  }
  return Number(date.getFullYear().toString().slice(-2));
};
/**
 * @hidden
 */
export const setYears = dateSetter('setFullYear');
/**
 * @hidden
 */
export const millisecondDigitsInFormat = format => {
  const result = format && format.match(/S+(\1)/);
  return result ? result[0].length : 0;
};
/**
 * @hidden
 */
export const millisecondStepFor = digits => {
  return Math.pow(10, 3 - digits);
};
/**
 * @hidden
 */
export const areDatePartsEqualTo = (date, year, month, day, hour, minutes, seconds, milliseconds) => {
  if (date && date.getFullYear() === year && date.getMonth() === month && date.getDate() === day && date.getHours() === hour && date.getMinutes() === minutes && date.getSeconds() === seconds && date.getMilliseconds() === milliseconds) {
    return true;
  }
  return false;
};
/**
 * @hidden
 */
export const isValidDate = value => isPresent(value) && value.getTime && isNumber(value.getTime());
/**
 * @hidden
 */
export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /Macintosh/i.test(navigator.userAgent);